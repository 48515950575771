<template lang="pug">
  .reservation-fee-brakedown
    Title(title="ota_management.booking_confirmation.form.fee_breakdown.title")
    .table
      table
        thead 
          tr 
            th
              span {{ $t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.header.details') }}
            th

        tbody
          tr 
            td 
              span {{ this.$t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.basic_fee') }}
            td 
              span {{ feeBreakdown.total_basic_fee }}
              span.yen 
                | {{ $t('general_settings.yen') }}

          tr(
            v-for="fee in optionFees"
            :class="{ sub: fee.isSub }"
            :key="fee.key"
          )
            td 
              span {{ fee.title }}
            td 
              span {{ fee.value }}
              span.yen(
                v-if="fee.value !== '-'"
              ) 
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.late_fee') }}
            td
              span {{ feeBreakdown.late_fee }}
              span.yen 
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.gasoline_fee') }}
            td
              span {{ feeBreakdown.gasoline_fee }}
              span.yen
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.late_night_rental_fee') }}
            td
              span {{ feeBreakdown.late_night_rental_fee }}
              span.yen
                | {{ $t('general_settings.yen') }}

          tr
            td
              span {{ this.$t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.drop_off_fee') }}
            td
              span {{ feeBreakdown.drop_off_fee }}
              span.yen
                | {{ $t('general_settings.yen') }}

        tfoot
          tr
            td
              span {{ $t('company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.total_amount') }}
            td
              span {{ feeBreakdown.total_fee }}
              span.yen {{ $t('general_settings.yen') }}
</template>

<script>
  // misc
  import { map } from "lodash-es"

  export default {
    components: {
      Title: () => import("@/components/CompanySystem/Title"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel")
    },

    props: {
      feeBreakdown: {
        type: Object,
        default: () => {}
      }
    },

    computed: {
      optionFees() {
        let fees = map(this.feeBreakdown.option_fees, option => {
          return {
            key: option.id,
            name: `option_${option.id}`,
            title: option.name,
            value: option.fee,
            isSub: true
          }
        })

        if (fees.length) {
          fees = [
            {
              key: "option",
              title: this.$t(`company_system.booking_confirmation.form.reservation_fee_breakdown.table.body.options`),
              value: "-",
              isSub: false
            },
            ...fees
          ]
        }

        return fees
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  .reservation-fee-brakedown
    width: 100%
  .table
    margin-bottom: 40px

    table
      width: 100%

      thead
        background-color: $default-gray-light
        color: #3B5472
        font-size: 13px

        th
          font-weight: 500
          width: 33%
          border: none

          td
            border-bottom: none

      tr
        height: 44px

        &.sub
          td:first-child
            padding-left: 32px

        &:last-child
          td
            border-bottom-width: 2px
        td
          border-bottom: 1px solid $default-purple-light-line


          .fee-input
            display: flex
            align-items: center

            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button
              -webkit-appearance: none
              margin: 0


            /* Firefox */
            input[type=number]
              -moz-appearance: textfield


      tfoot
        font-weight: bold

        tr
          td
            border-bottom: 1px solid $default-purple-light-line
            border-top: 1px solid $default-purple-light-line

            &:last-child
              .yen
                margin-left: 20px

      td
        vertical-align: middle

        .yen
            margin-left: 10px

        &:not(:first-child)
          text-align: right
          input
            text-align: right
</style>
